import cn from 'classnames'
import CustomImage from 'shared/components/CustomImage'
import { ArtworkImageLayout } from 'types/V3/Artwork'

type Props = {
  title: string
  src: string
  blurDataURL?: string
  layout?: ArtworkImageLayout
  sizes?: string
  bgTransparent?: boolean
  className?: string
}

const ArtworkImage = (props: Props) => {
  const {
    title,
    src,
    blurDataURL,
    layout = 'fill',
    sizes = '450px', // this is enough for the default thumbnail size
    bgTransparent,
    className,
  } = props

  return (
    <div
      className={cn(
        'relative box-content aspect-artwork w-full overflow-hidden transition-all',
        bgTransparent ? 'bg-transparent' : 'bg-black/50',
      )}
    >
      <CustomImage
        alt={title}
        src={src}
        placeholder="blur"
        blurDataURL={blurDataURL || '/images/placeholder.webp'}
        fill
        sizes={sizes}
        className={cn('object-center transition-all', layout === 'fill' ? 'object-cover' : 'object-contain', className)}
      />
    </div>
  )
}

export default ArtworkImage

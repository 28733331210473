import { Transition } from '@headlessui/react'
import MagnifyIcon from '../../../icons/MagnifyIcon'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import CustomImage from 'shared/components/CustomImage'
import useSmartSuggestion from 'shared/hooks/useSmartSuggestion'
import SpinnerIcon from '../../../icons/SpinnerIcon'

type SmartSuggestionsProp = {
  onSelectSmartSuggestion: (text: string) => void
}

const SmartSuggestions = (props: SmartSuggestionsProp) => {
  const { onSelectSmartSuggestion } = props

  const { data, isLoading } = useSmartSuggestion()
  const smartSuggestions = data?.suggestions
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [smartSuggestionIndex, setSmartSuggestionIndex] = useState(0)
  const [showSmartSuggestion, setShowSmartSuggestion] = useState(true)

  const handleSelectSmartSuggestion = useCallback(() => {
    if (!smartSuggestions) return
    onSelectSmartSuggestion(smartSuggestions[smartSuggestionIndex].query)
  }, [onSelectSmartSuggestion, smartSuggestionIndex, smartSuggestions])

  useEffect(() => {
    if (!smartSuggestions) return
    // an interval loop every 4 s
    const interval = setInterval(() => {
      setShowSmartSuggestion(false)
      setSmartSuggestionIndex((prev) => (prev === smartSuggestions.length - 1 ? 0 : prev + 1))
      timer.current = setTimeout(() => {
        setShowSmartSuggestion(true)
      }, 300)
    }, 4000)

    return () => {
      clearInterval(interval)
      if (timer.current) clearTimeout(timer.current)
    }
  }, [setSmartSuggestionIndex, timer, setShowSmartSuggestion, smartSuggestions])

  if (!smartSuggestions || isLoading) {
    return (
      <div className="flex h-20 w-full items-center justify-center">
        {!smartSuggestions && isLoading && <SpinnerIcon />}
        {!smartSuggestions && !isLoading && <div className="text-sm text-black">Unable to load suggestions</div>}
      </div>
    )
  }

  return (
    <Transition appear show={showSmartSuggestion} className="cursor-pointer" onClick={handleSelectSmartSuggestion}>
      <div className="flex items-center gap-1 px-0 text-xs normal-case text-black/60 lg:gap-3 lg:px-4 lg:text-base ">
        <MagnifyIcon className="h-6 w-6 lg:h-8 lg:w-8" />
        <span className="flex gap-1">
          Try
          <Transition.Child
            enter="transition-opacity ease-linear duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <span className="font-bold">
              {showSmartSuggestion && `"${smartSuggestions[smartSuggestionIndex].query}"`}
            </span>
          </Transition.Child>
        </span>
      </div>

      <Transition.Child
        enter="transition ease-in-out duration-500 transform"
        enterFrom="translate-y-full opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in-out duration-500 transform"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="translate-y-full opacity-0"
      >
        <div className="relative ml-8 mr-4 mt-2 flex h-[130px] max-w-[300px] lg:ml-16">
          {showSmartSuggestion &&
            smartSuggestions[smartSuggestionIndex].artworks.map((item, index) => (
              <SmartSuggestionImage key={index} imageUrl={item.imageMedium} index={index} />
            ))}
        </div>
      </Transition.Child>
    </Transition>
  )
}

export default memo(SmartSuggestions)

const SmartSuggestionImage = ({ imageUrl, index }: { imageUrl: string; index: number }) => {
  return (
    <div
      key={index}
      className={classNames(
        'absolute h-[100px] w-[88px] rounded-[4px]',
        index === 0 && 'inset-0 z-30 ',
        index === 1 && 'left-[20%] top-1 z-20',
        index === 2 && 'left-[40%] top-2 z-10',
        index === 3 && 'left-[60%] top-3',
      )}
    >
      <CustomImage src={imageUrl} alt="artwork" fill className="rounded-[4px] object-cover" />
    </div>
  )
}

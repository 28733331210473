import { MouseEvent, useCallback } from 'react'
import CloseIcon from 'shared/icons/CloseIcon'
import cn from 'classnames'
import { Popover } from '@headlessui/react'
import MagnifyIcon from '../../../icons/MagnifyIcon'
import { TwinkleMagnifyGlassIcon } from '../../../icons/TwinkleMagnifyGlassIcon'
import SmartSuggestions from './SmartSuggestions'
import RecentSearches from './RecentSearches'
import useHeaderSearchbarInput from '../../../hooks/useHeaderSearchbarInput'
import { CameraIcon } from '@heroicons/react/24/outline'
import ArtworkImage from 'shared/components/artwork/ArtworkImage'
import { useRouter } from 'next/router'
import SearchByImage from './SearchByImage'
import useAuth from 'hooks/authentication/useAuth'
import dynamic from 'next/dynamic'
import HeaderFindArtBanner from 'shared/components/layout/Header/HeaderFindArtBanner'

const RecentViewed = dynamic(() => import('./RecentViewed'))

type HeaderSearchBarProps = {
  placeholder?: string
  isTransparent?: boolean
  currentSearchText?: string
  onChange?: (text: string) => void
}

const HeaderSearchBar = (props: HeaderSearchBarProps) => {
  const { placeholder, isTransparent, currentSearchText, onChange } = props
  const router = useRouter()
  const { isLoggedIn } = useAuth()

  const {
    dropdownCloseBtnRef,
    dropdownBtnRef,
    inputRef,
    text,
    onInputChange,
    onClearButtonClick,
    handleOnKeyDown,
    goToSearch,
    isSearchByImageModeOn,
    setSearchByImageModeOn,
    setSearchByImageModeOff,
    searchByImageFile,
    onChangeImageToSearch,
    onClearImageClick,
  } = useHeaderSearchbarInput({
    initialSearchText: currentSearchText,
    onChange,
    initialSearchByImageMode: router.query.searchByImage === 'true',
  })
  const showImageInput = isSearchByImageModeOn && searchByImageFile
  const imageName = searchByImageFile?.fileName || ''
  const imageBase64Data = searchByImageFile?.base64Data || ''

  // when click on the input: open the tooltip, then focus on the input
  const handleOnClick = useCallback(() => {
    dropdownBtnRef.current?.click() // open drop-down
    inputRef.current?.focus() // focus on the input

    // if we are in search by image mode, close the search by image mode and remove the current image to search
    setSearchByImageModeOff()
    onChangeImageToSearch(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchByImageModeOff, dropdownBtnRef, inputRef])

  const handleClearInput = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      onClearButtonClick()
    },
    [onClearButtonClick],
  )

  const onDisplayImageUpload = useCallback(() => {
    dropdownBtnRef.current?.click()
    setSearchByImageModeOn()
    onClearButtonClick()
  }, [setSearchByImageModeOn, dropdownBtnRef, onClearButtonClick])

  const WordsSearchPanel = useCallback(
    () => (
      <>
        <SmartSuggestions onSelectSmartSuggestion={goToSearch} />

        <div className="mx-3">
          <RecentSearches onSelectRecentSearch={goToSearch} />
        </div>

        {isLoggedIn && (
          <div className="mx-3">
            <RecentViewed />
          </div>
        )}

        {/* find-art banner */}
        <div className="pt-2">
          <HeaderFindArtBanner />
        </div>
      </>
    ),
    [goToSearch, isLoggedIn],
  )

  const searchByWordInput = (
    <>
      <input
        onChange={onInputChange}
        onKeyDown={handleOnKeyDown}
        onClick={handleOnClick}
        className={cn(
          'w-full border-none bg-transparent text-black/70 focus:outline-0 focus:ring-0',
          isTransparent && 'placeholder:text-black/40',
        )}
        value={text}
        ref={inputRef}
        placeholder={placeholder}
      />

      {text && (
        <button onClick={handleClearInput} className="mr-2">
          <CloseIcon className="h-6 w-6 text-black/70" />
        </button>
      )}
    </>
  )

  const searchByImageInput = (
    <>
      <div className="flex w-[270px] items-center gap-2 p-3">
        <div className="relative aspect-square w-7 overflow-hidden rounded-[5px]">
          <ArtworkImage title={imageName} src={imageBase64Data} layout="fill" />
        </div>

        {/* title */}
        <p className="line-clamp-1 normal-case">{imageName}</p>
      </div>
      {imageBase64Data && (
        <button onClick={onClearImageClick} className="mr-2">
          <CloseIcon className="h-6 w-6 text-black/70" />
        </button>
      )}
    </>
  )

  return (
    <div className={cn('relative w-full min-w-[400px]')}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button ref={dropdownBtnRef} />

            <div
              className={cn(
                'hidden w-full items-center gap-1 border border-black/10 bg-[#FDFDFD] px-5 py-0  lg:inline-flex',
                isTransparent && 'bg-white/70 backdrop-blur-xl',
                open ? 'rounded-t-[20px]' : 'rounded-full',
              )}
            >
              <div className="w-6 text-black">
                {open && <TwinkleMagnifyGlassIcon className="h-6 w-6 opacity-60" />}
                {!open && <MagnifyIcon className="h-8 w-8 text-black/60" />}
              </div>
              {!showImageInput && searchByWordInput}
              {showImageInput && searchByImageInput}
              <button onClick={onDisplayImageUpload}>
                <CameraIcon className="h-6 w-6 text-black/70" />
              </button>
            </div>

            <Popover.Panel
              className={cn(
                'absolute left-0 z-50 min-w-[400px] space-y-2',
                'overflow-hidden rounded-b-[24px] bg-white/95 pt-1 backdrop-blur-2xl',
                'border border-black/10 shadow-lg',
                'bottom-0 h-fit translate-y-full',
              )}
            >
              {({ close }) => (
                <>
                  {!isSearchByImageModeOn && <WordsSearchPanel />}
                  {isSearchByImageModeOn && <SearchByImage onChange={onChangeImageToSearch} />}

                  {/* close button */}
                  <button
                    className="hidden" // so it doesn't affect the layout's spacing (space-y-2 class above)
                    ref={dropdownCloseBtnRef}
                    onClick={() => {
                      close()
                    }}
                  />
                </>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  )
}

export default HeaderSearchBar

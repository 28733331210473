import Image, { ImageProps } from 'next/image'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'

export interface CustomImageProps extends Omit<ImageProps, 'src' | 'alt'> {
  fallbackSrc?: string
  src?: string | null
  alt?: string
}

const DEFAULT_FALLBACK_IMAGE_URL = '/images/image-not-found.png'
const DEFAULT_BLUR_IMAGE_URL = '/images/placeholder.webp'

const CustomImage = (props: CustomImageProps) => {
  const { fallbackSrc, placeholder, blurDataURL, fill, width, height, src, alt = 'image alt', ...rest } = props
  const validFallbackSrc = fallbackSrc || DEFAULT_FALLBACK_IMAGE_URL
  const [imageScr, setImageSrc] = useState(src || validFallbackSrc)
  const onError = useCallback(
    (e: SyntheticEvent<HTMLImageElement>) => {
      e.currentTarget.onerror = null // prevents looping
      setImageSrc(validFallbackSrc)
    },
    [validFallbackSrc],
  )

  useEffect(() => {
    setImageSrc(src || validFallbackSrc)
  }, [src, validFallbackSrc])

  const otherProps = {
    ...rest,
    placeholder,
    src: imageScr,
    ...(fill ? { fill: true } : { width, height }),
    ...(placeholder === 'blur' && { blurDataURL: blurDataURL || DEFAULT_BLUR_IMAGE_URL }),
  }

  return <Image {...otherProps} onError={onError} alt={alt} />
}

export default CustomImage

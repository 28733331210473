import { useRouter } from 'next/router'
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'
import { useBoolean, useLocalStorage } from 'usehooks-ts'

type Props = {
  initialSearchText?: string
  onChange?: (text: string) => void
  closeSearchModal?: () => void
  initialSearchByImageMode?: boolean
}
const useHeaderSearchbarInput = ({
  initialSearchText,
  onChange,
  closeSearchModal,
  initialSearchByImageMode,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownBtnRef = useRef<HTMLButtonElement>(null)
  const dropdownCloseBtnRef = useRef<HTMLButtonElement>(null)
  const router = useRouter()
  const [text, setText] = useState(initialSearchText || '')
  const [recentSearches, setRecentSearches] = useLocalStorage<Array<string>>('recentSearches', [])
  const [searchByImageFile, setSearchByImageFile] = useLocalStorage<{ base64Data: string; fileName: string } | null>(
    'searchByImageFile',
    null,
  )

  const {
    value: isSearchByImageModeOn,
    setTrue: setSearchByImageModeOn,
    setFalse: setSearchByImageModeOff,
  } = useBoolean(initialSearchByImageMode || false) // Sync with the query param initially

  // Handlers
  const closeDropdown = useCallback(() => {
    if (!dropdownCloseBtnRef?.current) return
    setTimeout(() => {
      dropdownCloseBtnRef?.current?.click()
    }, 100)
  }, [])

  const onClearImageClick = useCallback(async () => {
    await router.replace('/discover/artworks')
    setSearchByImageModeOff()
    setSearchByImageFile(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchByImageFile, setSearchByImageModeOff])

  const onChangeImageToSearch = useCallback(
    (file: File | null) => {
      if (!file) {
        setSearchByImageFile(null)
        return
      }

      // Convert image to base64
      const reader = new FileReader()
      reader.onload = (event) => {
        const base64Data = event.target?.result as string
        // Save the image to local storage
        setSearchByImageFile({
          base64Data,
          fileName: file.name,
        })
      }

      reader.readAsDataURL(file)
      const destination = '/discover/artworks-by-image?searchByImage=true'
      if (router.pathname === 'discover/artworks-by-image') {
        router.replace(destination, undefined, { shallow: true })
      } else {
        router.push(destination)
      }

      // Close the dropdown after selecting the image
      // Or close closeSearchModal if on search page
      closeDropdown()
      closeSearchModal && closeSearchModal()
    },
    [closeSearchModal, setSearchByImageFile, closeDropdown, router],
  )

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setText(value)
      if (!value) onChange && onChange('')
    },
    [onChange],
  )

  const onClearButtonClick = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ''
    setText('')
    onChange && onChange('')
  }, [onChange])

  const storeRecentSearches = useCallback(
    (newSearch: string) => {
      setRecentSearches([newSearch].concat(recentSearches.filter((item) => item !== newSearch)).slice(0, 5))
    },
    [recentSearches, setRecentSearches],
  )

  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return
      trackAmplitudeCustomEvent('search_keyword_input', {
        keyword: text,
      })
      storeRecentSearches(text)
      closeDropdown()
      // if we are on Discover page (onChange is defined), call the onChange function, else lead user to the Discover page with the param
      if (typeof onChange === 'function') {
        onChange(text)
      } else router.push(`/discover/artworks?search=${text}`)

      closeSearchModal && closeSearchModal()
    },
    [closeSearchModal, onChange, router, storeRecentSearches, text, closeDropdown],
  )

  const goToSearch = useCallback(
    (searchText: string) => {
      setText(searchText)
      storeRecentSearches(searchText)
      closeDropdown()
      if (typeof onChange === 'function') {
        onChange(searchText)
      } else router.push(`/discover/artworks?search=${searchText}`)
    },
    [onChange, router, storeRecentSearches, closeDropdown],
  )

  // Side effects
  useEffect(() => {
    if (!isSearchByImageModeOn) {
      setSearchByImageFile(null) // Sync with isSearchByImageModeOn state when component did mount
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    dropdownCloseBtnRef,
    dropdownBtnRef,
    inputRef,
    text,
    onInputChange,
    onClearButtonClick,
    handleOnKeyDown,
    goToSearch,
    closeDropdown,
    isSearchByImageModeOn,
    setSearchByImageModeOn,
    setSearchByImageModeOff,
    searchByImageFile,
    onChangeImageToSearch,
    onClearImageClick,
  }
}

export default useHeaderSearchbarInput

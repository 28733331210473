import Link from 'next/link'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'

const HeaderFindArtBanner = () => {
  const onFindArtButtonClick = () => {
    trackAmplitudeCustomEvent('find_art_clicked', { location: 'header-search-drawer' })
  }

  return (
    <div className="w-full bg-mint-green p-3 text-center normal-case text-black">
      <span>Ready to fill your blank wall? Take our&nbsp;</span>

      <Link
        passHref
        href="/find-art"
        role="button"
        className="font-semibold text-primary hover:underline"
        onClick={onFindArtButtonClick}
      >
        Find Art Quiz.
      </Link>
    </div>
  )
}

export default HeaderFindArtBanner
